

const Body = () => {
  return (
    <div>
        Body
    </div>
  )
}

export default Body
