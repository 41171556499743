import React from "react"
import styled from "styled-components"

const Sidebar = () => {
  return (
    <div>
        <Container>

        </Container>
    </div>
  )
}

export default Sidebar

const Container = styled.div`
width: 280px;
height: 100vh;
display: flex;
flex-direction: column;
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
background-color: #fff;
`;


